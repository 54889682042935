// *******************************************
// RegistrationFee Responses View Data Factory
// *******************************************
import { getPerString, getPrcString } from "../../api/UiUtils";
/**
 * getResponsesSummaryItems
 *
 * @param {object} resData
 * @returns itemsArr
 */
export function getResponsesSummaryItems(resData) {
  console.log("RegistrationFee.", "getResponsesSummaryItems()");
  let itemsArr = [];

  if (resData) {
    if (resData["ret_등기비용총계"] > 0) {
      itemsArr.push({
        name: "총 등기비용",
        value: getPrcString(resData["ret_등기비용총계"]),
        additionalInfo: "부가정보",
      });
    }
  }

  return itemsArr;
}

/**
 * getResponsesDetailItems
 *
 * @param {object} resData
 * @returns itemsArr
 */
export function getResponsesDetailItems(resData) {
  console.log("RegistrationFee.", "getResponsesDetailItems()");
  let itemsArr = [];

  if (resData) {
    if (resData["ret_거래금액"] > 0) {
      itemsArr.push({
        type: "data",
        name: "금액",
        value: getPrcString(resData["ret_거래금액"]),
        additionalInfo: "부가정보",
      });
    }
    if (resData["ret_시가표준액"] > 0) {
      itemsArr.push({
        type: "data",
        name: "시가표준액",
        value: getPrcString(resData["ret_시가표준액"]),
        additionalInfo: "부가정보",
      });
    }
    if (resData["ret_취득세율"] > 0) {
      itemsArr.push({
        type: "data",
        name: "취득세율",
        value: getPerString(resData["ret_취득세율"]),
        additionalInfo: "부가정보",
      });
    }
    if (resData["ret_지방교육세포함_취득세율"] > 0) {
      itemsArr.push({
        type: "data",
        name: "지방교육세 포함 취득세율",
        value: getPerString(resData["ret_지방교육세포함_취득세율"]),
        additionalInfo: "부가정보",
      });
    }
    if (resData["ret_농어촌특별세포함_취득세율"] > 0) {
      itemsArr.push({
        type: "data",
        name: "농어촌특별세 포함 취득세율",
        value: getPerString(resData["ret_농어촌특별세포함_취득세율"]),
        additionalInfo: "부가정보",
      });
    }
    if (resData["ret_취득세"] > 0) {
      itemsArr.push({
        type: "data",
        name: "취득세",
        value: getPrcString(resData["ret_취득세"]),
        additionalInfo: "부가정보",
      });
    }
    if (resData["ret_부가세"] > 0) {
      itemsArr.push({
        type: "data",
        name: "부가세",
        value: getPrcString(resData["ret_부가세"]),
        additionalInfo: "부가정보",
      });
    }
    if (resData["ret_건물분_채권매입금액"] > 0) {
      itemsArr.push({
        type: "data",
        name: "건물분 채권매입금액",
        value: getPrcString(resData["ret_건물분_채권매입금액"]),
        additionalInfo: "부가정보",
      });
    }
    if (resData["ret_토지분_채권매입금액"] > 0) {
      itemsArr.push({
        type: "data",
        name: "토지분 채권매입금액",
        value: getPrcString(resData["ret_토지분_채권매입금액"]),
        additionalInfo: "부가정보",
      });
    }
    if (resData["ret_채권매입금액"] > 0) {
      itemsArr.push({
        type: "data",
        name: "채권매입금액",
        value: getPrcString(resData["ret_채권매입금액"]),
        additionalInfo: "부가정보",
      });
    }

    itemsArr.push({
      type: "hr",
    });

    if (resData["ret_채권할인료"] > 0) {
      itemsArr.push({
        type: "data",
        name: "채권할인료",
        value: getPrcString(resData["ret_채권할인료"]),
        additionalInfo: "부가정보",
      });
    }
    if (resData["ret_제증명비용"] > 0) {
      itemsArr.push({
        type: "data",
        name: "제증명",
        value: getPrcString(resData["ret_제증명비용"]),
        additionalInfo: "부가정보",
      });
    }

    itemsArr.push({
      type: "hr",
    });

    if (resData["ret_수입증지비용"] > 0) {
      itemsArr.push({
        type: "data",
        name: "수입증지",
        value: getPrcString(resData["ret_수입증지비용"]),
        additionalInfo: "부가정보",
      });
    }
    if (resData["ret_수입인지비용"] > 0) {
      itemsArr.push({
        type: "data",
        name: "수입인지",
        value: getPrcString(resData["ret_수입인지비용"]),
        additionalInfo: "부가정보",
      });
    }
    if (resData["ret_공과금소계"] > 0) {
      itemsArr.push({
        type: "data",
        name: "공과금 소계",
        value: getPrcString(resData["ret_공과금소계"]),
        additionalInfo: "부가정보",
      });
    }

    itemsArr.push({
      type: "hr",
    });

    if (resData["ret_등기비용총계"] > 0) {
      itemsArr.push({
        type: "data",
        name: "총계",
        value: getPrcString(resData["ret_등기비용총계"]),
        additionalInfo: "부가정보",
      });
    }
  }

  return itemsArr;
}
