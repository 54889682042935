import React from 'react';
import { Route, Routes } from 'react-router-dom';
import menus from '../menus';

function MainRoute(props) {
  return (
    <Routes>
      <Route path="/" exact={true} element={null} />
      {menus.categories.map((catItem, catIdx) => {
        if (catItem.type === 'category') {
          const menuEl = catItem.items.map((menuItem, menuIdx) => {
            if (menuItem.type === 'path') {
              return (
                <Route path={menuItem.path} exact={true} element={menuItem.component} key={'r-'+catIdx+'-'+menuIdx} />
              );
            } else {
              return null;
            }
          });
          return menuEl;
        } else if (catItem.type === 'path') {
          return (
            <Route path={catItem.path} exact={true} element={catItem.component} key={'r-'+catIdx} />
          );
        } else {
          return null;
        }
      })}
    </Routes>
    // <Routes>
    //   <Route path="/fee/fee1" exact={true} element={<BrokerageFee />} />  
    //   <Route path="/fee/fee2" exact={true} element={<LegalStaffFee />} /> 

    //   <Route path="/group2/tax1" exact={true} element={null} />
    //   <Route path="/group2/tax2" exact={true} element={null} />
    //   <Route path="/group2/tax3" exact={true} element={null} />
    //   <Route path="/group2/tax4" exact={true} element={null} />

    //   <Route path="/tax/tax4" exact={true} element={null} />
    // </Routes>
  );
}

export default MainRoute;