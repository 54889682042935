// *************************************
// HouseBond Responses View Data Factory
// *************************************
import { getPerString, getPrcString } from "../../api/UiUtils";
/**
 * getResponsesSummaryItems
 *
 * @param {object} resData
 * @returns itemsArr
 */
export function getResponsesSummaryItems(resData) {
  console.log("HouseBond.", "getResponsesSummaryItems()");
  let itemsArr = [];

  if (resData) {
    if (resData["ret_채권매입금액"] > 0) {
      itemsArr.push({
        name: "채권 매입 금액",
        value: getPrcString(resData["ret_채권매입금액"]),
        additionalInfo: "부가정보",
      });
    }
  }
  if (resData) {
    if (resData["ret_채권할인료"] > 0) {
      itemsArr.push({
        name: "채권 할인료",
        value: getPrcString(resData["ret_채권할인료"]),
        additionalInfo: "부가정보",
      });
    }
  }

  return itemsArr;
}

/**
 * getResponsesDetailItems
 *
 * @param {object} resData
 * @returns itemsArr
 */
export function getResponsesDetailItems(resData) {
  console.log("HouseBond.", "getResponsesDetailItems()");
  let itemsArr = [];

  if (resData) {
    if (resData["ret_시가표준액"] > 0) {
      itemsArr.push({
        type: "data",
        name: "시가표준액",
        value: getPrcString(resData["ret_시가표준액"]),
        additionalInfo: "부가정보",
      });
    }
    if (resData["ret_건물시가표준액"] > 0) {
      itemsArr.push({
        type: "data",
        name: "건물시가표준액",
        value: getPrcString(resData["ret_건물시가표준액"]),
        additionalInfo: "부가정보",
      });
    }
    if (resData["ret_토지시가표준액"] > 0) {
      itemsArr.push({
        type: "data",
        name: "토지시가표준액",
        value: getPrcString(resData["ret_토지시가표준액"]),
        additionalInfo: "부가정보",
      });
    }
    if (resData["ret_채권매입금액"] > 0) {
      itemsArr.push({
        type: "data",
        name: "채권매입금액",
        value: getPrcString(resData["ret_채권매입금액"]),
        additionalInfo: "부가정보",
      });
    }
    if (resData["ret_건물분_채권매입금액"] > 0) {
      itemsArr.push({
        type: "data",
        name: "건물분 채권매입금액",
        value: getPrcString(resData["ret_건물분_채권매입금액"]),
        additionalInfo: "부가정보",
      });
    }
    if (resData["ret_토지분_채권매입금액"] > 0) {
      itemsArr.push({
        type: "data",
        name: "토지분 채권매입금액",
        value: getPrcString(resData["ret_토지분_채권매입금액"]),
        additionalInfo: "부가정보",
      });
    }
    if (resData["ret_채권할인료"] > 0) {
      itemsArr.push({
        type: "data",
        name: "채권할인료",
        value: getPrcString(resData["ret_채권할인료"]),
        additionalInfo: "부가정보",
      });
    }
  }

  return itemsArr;
}
