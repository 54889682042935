import { useEffect, useState } from "react";
import { BsBuilding } from "react-icons/bs";
import { Button, Col, Form, Row } from "react-bootstrap";
import { checkChangeHandler, inputChangeHandler, radioSelectHandler } from "../../api/UiHandlers";
import FormInputRadioModule from "../FormItems/FormInputRadioModule";
import FormInputCheckModule from "../FormItems/FormInputCheckModule";
import FormInputNumberModule from "../FormItems/FormInputNumberModule";
import Responses from "../Responses/Responses";
import getURLParamValue from "../../api/getURLParamValue";
import sendCalReq from "../../api/sendCalReq";
import { hideFormItem, showFormItem, validatePrc } from "../../api/UiUtils";
import "../../style/global.css";
import classes from "./RegistrationFree.module.css";
import DetailGuide from "../DetailGuide/DetailGuide";
import {
  getResponsesSummaryItems,
  getResponsesDetailItems,
} from "../../api/ResponsesViewDataFactory/RegistrationFeeRVDFactory";

function RegistrationFree() {
  const [optValues, setOptValues] = useState({
    opt1: 1, // 대상조건
    opt2: 1, // 취득대상
    opt3: 1, // 대상지역
    opt4: 1, // 대상면적
    opt5: 1, // 대상주택
    chk1: false, // 셀프등기
    chk2: false, // 법인
    chk3: false, // 조정대상지역
    chk4: false, // 임대사업자_최초분양
    chk5: false, // 생애최초구입
    prc1: "", // 거래금액
    prc2: "", // 시가표준액
    prc3: "", // 건물시가표준액
    prc4: "", // 토지시가표준액
  });
  const [show, setShow] = useState({
    opt3: true,
    opt4: true,
    opt5: true,
    chk4: true,
    chk5: true,
    prc2: true,
    prc3: false,
    prc4: false,
  });
  const [focus, setFocus] = useState({
    prc1: false,
    prc2: false,
    prc3: false,
    prc4: false,
  });
  const [reqData, setReqData] = useState(null);
  const [resData, setResData] = useState(null);
  const [loading, setLoading] = useState(null);
  const [error, setError] = useState(null);

  const prcPlaceholder = "1만원이상 금액입력";

  useEffect(() => {
    // show.opt3
    if (optValues.opt2 === 1) {
      showFormItem("opt3", setShow, setOptValues);
    } else {
      hideFormItem("opt3", setShow, setFocus, setOptValues);
    }

    // show.opt4
    if (optValues.opt2 === 1 || optValues.opt2 === 2) {
      showFormItem("opt4", setShow, setOptValues);
    } else {
      hideFormItem("opt4", setShow, setFocus, setOptValues);
    }

    // show.opt5
    if (optValues.opt2 === 1) {
      showFormItem("opt5", setShow, setOptValues);
    } else {
      hideFormItem("opt5", setShow, setFocus, setOptValues);
    }

    // show.chk4
    if (optValues.opt2 === 1 || optValues.opt2 === 2) {
      showFormItem("chk4", setShow, setOptValues);
    } else {
      hideFormItem("chk4", setShow, setFocus, setOptValues);
    }

    // show.chk5
    if (optValues.opt2 === 1 && optValues.opt5 === 1) {
      showFormItem("chk5", setShow, setOptValues);
    } else {
      hideFormItem("chk5", setShow, setFocus, setOptValues);
    }

    // show.prc2
    if (optValues.opt2 === 1 || optValues.opt2 === 4) {
      showFormItem("prc2", setShow, setOptValues);
    } else {
      hideFormItem("prc2", setShow, setFocus, setOptValues);
    }

    // show.prc3
    if (optValues.opt2 === 2 || optValues.opt2 === 3) {
      showFormItem("prc3", setShow, setOptValues);
    } else {
      hideFormItem("prc3", setShow, setFocus, setOptValues);
    }

    // show.prc4
    if (optValues.opt2 === 2 || optValues.opt2 === 3) {
      showFormItem("prc4", setShow, setOptValues);
    } else {
      hideFormItem("prc4", setShow, setFocus, setOptValues);
    }
  }, [optValues.opt2, optValues.opt5]);

  function handleSubmit(e) {
    const opt1 = optValues.opt1;
    const opt2 = optValues.opt2;
    const opt3 = optValues.opt3;
    const opt4 = optValues.opt4;
    const opt5 = optValues.opt5;
    const chk1 = optValues.chk1;
    const chk2 = optValues.chk2;
    const chk3 = optValues.chk3;
    const chk4 = optValues.chk4;
    const chk5 = optValues.chk5;
    const prc1 = optValues.prc1;
    const prc2 = optValues.prc2;
    const prc3 = optValues.prc3;
    const prc4 = optValues.prc4;

    setReqData({});

    e.preventDefault();
    let reqReady = true;

    // validate price1
    reqReady &= validatePrc(true, "prc1", prc1, setFocus, setOptValues);
    // validate price2
    reqReady &= validatePrc(show.prc2, "prc2", prc2, setFocus, setOptValues);
    // validate price3
    reqReady &= validatePrc(show.prc3, "prc3", prc3, setFocus, setOptValues);
    // validate price4
    reqReady &= validatePrc(show.prc4, "prc4", prc4, setFocus, setOptValues);

    if (!reqReady) return;

    console.log("submit");

    let reqObj = {};
    reqObj["대상조건"] = opt1;
    reqObj["취득대상"] = opt2;
    reqObj["대상지역"] = opt3;
    reqObj["대상면적"] = opt4;
    reqObj["대상주택"] = opt5;
    reqObj["셀프등기"] = chk1;
    reqObj["법인"] = chk2;
    reqObj["조정대상지역"] = chk3;
    reqObj["임대사업자_최초분양"] = chk4;
    reqObj["생애최초분양"] = chk5;
    reqObj["거래금액"] = prc1 === "-1" ? -1 : parseInt(prc1);
    reqObj["시가표준액"] = prc2 === "-1" ? -1 : parseInt(prc2);
    reqObj["건물시가표준액"] = prc3 === "-1" ? -1 : parseInt(prc3);
    reqObj["토지시가표준액"] = prc4 === "-1" ? -1 : parseInt(prc4);

    sendCalReq(
      "/v1/registration_fee",
      reqObj,
      setReqData,
      setResData,
      setLoading,
      setError,
      getURLParamValue("s")
    );
  }

  function handleRadioSelect(name, val) {
    radioSelectHandler(name, val, setOptValues, setFocus, true);
  }

  function handleCheckChange(name, val) {
    checkChangeHandler(name, val, setOptValues, true);
  }

  function handleInputChange(name, val) {
    inputChangeHandler(name, val, setOptValues, setFocus, true);
  }

  function freeFocus() {
    setFocus((prevFocus) => ({
      ...prevFocus,
      prc1: false,
      prc2: false,
      prc3: false,
      prc4: false,
    }));
  }

  // Responses show
  const responsesShow = resData && !error;

  const errorString = error ? <pre>{error.toString()}</pre> : null;

  return (
    <>
      <Row className={`global-calTitle ${classes.calTitleCustom}`}>
        <Col>
          <div>
            <BsBuilding style={{ margin: "0 0 0.5rem 0" }} /> 등 기 비 용
          </div>
        </Col>
      </Row>
      <Row className={`global-requestForm`}>
        <Col>
          <Form onSubmit={handleSubmit}>
            <FormInputRadioModule
              title="대상조건"
              name="opt1"
              items={[
                { label: "일반", value: 1 },
                { label: "주택임대사업자", value: 2 },
                { label: "일반임대사업자", value: 3 },
              ]}
              value={optValues.opt1}
              required
              onSelect={handleRadioSelect}
              xsL={12}
              xsR={12}
            />
            <FormInputRadioModule
              title="취득대상"
              name="opt2"
              items={[
                { label: "주택", value: 1 },
                { label: "오피스텔", value: 2 },
                { label: "기타건물", value: 3 },
                { label: "토지", value: 4 },
              ]}
              value={optValues.opt2}
              required
              onSelect={handleRadioSelect}
              xsL={12}
              xsR={12}
            />
            {show.opt3 && (
              <FormInputRadioModule
                title="대상지역"
                name="opt3"
                items={[
                  { label: "서울", value: 1 },
                  { label: "수도권", value: 2 },
                  { label: "광역시", value: 3 },
                  { label: "기타지역", value: 4 },
                ]}
                value={optValues.opt3}
                required
                onSelect={handleRadioSelect}
                xsL={12}
                xsR={12}
              />
            )}
            {show.opt4 && (
              <FormInputRadioModule
                title="대상면적"
                name="opt4"
                items={[
                  { label: "40m²이하", value: 1 },
                  { label: "60m²이하", value: 2 },
                  { label: "85m²이하", value: 3 },
                  { label: "85m²초과", value: 4 },
                ]}
                value={optValues.opt4}
                required
                onSelect={handleRadioSelect}
                xsL={12}
                xsR={12}
              />
            )}
            {show.opt5 && (
              <FormInputRadioModule
                title="대상주택"
                name="opt5"
                items={[
                  { label: "1주택자", value: 1 },
                  { label: "2주택자", value: 2 },
                  { label: "3주택자", value: 3 },
                  { label: "4주택자", value: 4 },
                ]}
                value={optValues.opt5}
                required
                onSelect={handleRadioSelect}
                xsL={12}
                xsR={12}
              />
            )}
            <FormInputCheckModule
              title="셀프등기"
              name="chk1"
              label=""
              onChange={handleCheckChange}
              xsL={6}
              xsR={6}
            />
            <FormInputCheckModule
              title="법인"
              name="chk2"
              label=""
              onChange={handleCheckChange}
              xsL={6}
              xsR={6}
            />
            <FormInputCheckModule
              title="조정대상지역"
              name="chk3"
              label=""
              onChange={handleCheckChange}
              xsL={6}
              xsR={6}
            />
            {show.chk4 && (
              <FormInputCheckModule
                title="임대사업자 최초분양"
                name="chk4"
                label=""
                onChange={handleCheckChange}
                xsL={6}
                xsR={6}
              />
            )}
            {show.chk5 && (
              <FormInputCheckModule
                title="생애최초구입"
                name="chk5"
                label=""
                onChange={handleCheckChange}
                xsL={6}
                xsR={6}
              />
            )}
            <FormInputNumberModule
              type="price"
              title="거래금액"
              name="prc1"
              value={optValues.prc1}
              required={false}
              focused={focus.prc1}
              placeholder={prcPlaceholder}
              onChange={handleInputChange}
            />
            {show.prc2 && (
              <FormInputNumberModule
                type="price"
                title="시가표준액"
                name="prc2"
                value={optValues.prc2}
                required={false}
                focused={focus.prc2}
                placeholder={prcPlaceholder}
                onChange={handleInputChange}
              />
            )}
            {show.prc3 && (
              <FormInputNumberModule
                type="price"
                title="건물시가표준액"
                name="prc3"
                value={optValues.prc3}
                required={false}
                focused={focus.prc3}
                placeholder={prcPlaceholder}
                onChange={handleInputChange}
              />
            )}
            {show.prc4 && (
              <FormInputNumberModule
                type="price"
                title="토지시가표준액"
                name="prc4"
                value={optValues.prc4}
                required={false}
                focused={focus.prc4}
                placeholder={prcPlaceholder}
                onChange={handleInputChange}
              />
            )}
            <Row className={`global-requestButtonRow`}>
              <Col className="d-grid gap-2">
                <Button type="submit" className={`global-requestButton`}>
                  계{"\u00A0\u00A0\u00A0\u00A0\u00A0\u00A0"}산
                </Button>
              </Col>
            </Row>
          </Form>
        </Col>
      </Row>
      <Responses
        show={responsesShow}
        resData={resData}
        getResponsesSummaryItems={getResponsesSummaryItems}
        getResponsesDetailItems={getResponsesDetailItems}
      />
      <DetailGuide
        title="등 기 비 용"
        descArr={[
          "It is a long established fact that a reader will be distracted by the readable content of a page when looking at its layout. The point of using Lorem Ipsum is that it has a more-or-less normal distribution of letters, as opposed to using 'Content here, content here'",

          "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged. It was popularised in the 1960s with the release of Letraset sheets containing Lorem Ipsum passages, and more recently with desktop publishing software like Aldus PageMaker including versions of Lorem Ipsum.",

          'The standard chunk of Lorem Ipsum used since the 1500s is reproduced below for those interested. Sections 1.10.32 and 1.10.33 from "de Finibus Bonorum et Malorum" by Cicero are also reproduced in their exact original form, accompanied by English versions from the 1914 translation by H. Rackham.',
        ]}
      />
      <Row className="testData">
        <Col xs="12">
          <br />
          <br />
          <p>Request Data</p>
          <pre>{reqData && JSON.stringify(reqData, null, 4)}</pre>
        </Col>
      </Row>
      <Row className="testData">
        <Col xs="12">
          <br />
          <br />
          <p>Response Data</p>
          {resData && <pre>{JSON.stringify(resData, null, 4)}</pre>}
          {error && errorString}
        </Col>
      </Row>
    </>
  );
}

export default RegistrationFree;
