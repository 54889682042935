// ************************************
// StampTax Responses View Data Factory
// ************************************
import { getPerString, getPrcString } from "../../api/UiUtils";
/**
 * getResponsesSummaryItems
 *
 * @param {object} resData
 * @returns itemsArr
 */
export function getResponsesSummaryItems(resData) {
  console.log("StampTax.", "getResponsesSummaryItems()");
  let itemsArr = [];

  if (resData) {
    if (resData["ret_인지세"] > 0) {
      itemsArr.push({
        name: "인지세",
        value: getPrcString(resData["ret_인지세"]),
        additionalInfo: "부가정보",
      });
    }
  }

  return itemsArr;
}

/**
 * getResponsesDetailItems
 *
 * @param {object} resData
 * @returns itemsArr
 */
export function getResponsesDetailItems(resData) {
  console.log("StampTax.", "getResponsesDetailItems()");
  let itemsArr = [];

  if (resData) {
    if (resData["ret_거래금액"] > 0) {
      itemsArr.push({
        type: "data",
        name: "거래금액",
        value: getPrcString(resData["ret_거래금액"]),
        additionalInfo: "부가정보",
      });
    }
    if (resData["ret_인지세"] > 0) {
      itemsArr.push({
        type: "data",
        name: "인지세",
        value: getPrcString(resData["ret_인지세"]),
        additionalInfo: "부가정보",
      });
    }
  }

  return itemsArr;
}
