import { useState } from "react";
import { BsBuilding } from "react-icons/bs";
import { Button, Col, Form, Row } from "react-bootstrap";
import getURLParamValue from "../../api/getURLParamValue";
import sendCalReq from "../../api/sendCalReq";
import FormInputRadioModule from "../FormItems/FormInputRadioModule";
import FormInputCheckModule from "../FormItems/FormInputCheckModule";
import FormInputNumberModule from "../FormItems/FormInputNumberModule";
import Responses from "../Responses/Responses";
import {
  checkChangeHandler,
  inputChangeHandler,
  radioSelectHandler,
} from "../../api/UiHandlers";
import { validatePrc } from "../../api/UiUtils";
import classes from "./LegalStaffFee.module.css";
import DetailGuide from "../DetailGuide/DetailGuide";
import {
  getResponsesSummaryItems,
  getResponsesDetailItems,
} from "../../api/ResponsesViewDataFactory/LegalStaffFreeRVDFactory";

function LegalStaffFee() {
  const [optValues, setOptValues] = useState({
    opt1: 1, // 거래대상
    chk1: false, // 공공비용포함
    prc1: "", // 매매가
  });
  const [focus, setFocus] = useState({
    prc1: false,
  });
  const [reqData, setReqData] = useState(null);
  const [resData, setResData] = useState(null);
  const [loading, setLoading] = useState(null);
  const [error, setError] = useState(null);

  const prcPlaceholder = "금액 입력";

  function handleSubmit(e) {
    const opt1 = optValues.opt1;
    const chk1 = optValues.chk1;
    const prc1 = optValues.prc1;

    setReqData({});

    e.preventDefault();
    let reqReady = true;

    // validate prc1 : 대출금액
    reqReady &= validatePrc(true, "prc1", prc1, setFocus, setOptValues);

    if (!reqReady) return;
    
    let reqObj = {};
    reqObj["거래대상"] = opt1;
    reqObj["공공비용포함"] = chk1;
    reqObj["매매가"] = prc1 === "-1" ? -1 : parseInt(prc1) * 10_000;

    sendCalReq(
      "/v1/judical_fee",
      reqObj,
      setReqData,
      setResData,
      setLoading,
      setError,
      getURLParamValue("s"),
    );
  }

  function handleRadioSelect(name, val) {
    radioSelectHandler(name, val, setOptValues, setFocus, true);
  }

  function handleCheckChange(name, val) {
    checkChangeHandler(name, val, setOptValues, true);
  }

  function handleInputChange(name, val) {
    inputChangeHandler(name, val, setOptValues, setFocus, true);
  }

  function freeFocus() {
    setFocus((prevFocus) => ({
      ...prevFocus,
      prc1: false,
    }));
  }

  // Responses show
  const responsesShow = resData && !error;

  const errorString = error ? <pre>{error.toString()}</pre> : null;
 
  return (
    <>
      <Row className={`global-calTitle ${classes.calTitleCustom}`}>
        <Col>
          <div>
            <BsBuilding style={{ margin: "0 0 0.5rem 0" }} /> 법 무 사 보 수
          </div>
        </Col>
      </Row>
      <Row className={`global-requestForm`}>
        <Col>
          <Form onSubmit={handleSubmit}>
            <FormInputRadioModule
              title="상환방법"
              name="opt1"
              items={[
                { label: "주택", value: 1 },
                { label: "그외건물", value: 2 },
              ]}
              value={optValues.opt1}
              required
              onSelect={handleRadioSelect}
            />
            <FormInputCheckModule
              title="공공비용(인지,증지)포함"
              name="chk1"
              label=""
              onChange={handleCheckChange}
              xsL="8"
              xsR="4"
              mdL="8"
              mdR="4"
            />
            <FormInputNumberModule
              type="price"
              title="매매가"
              name="prc1"
              value={optValues.prc1}
              required={false}
              focused={focus.prc1}
              placeholder={prcPlaceholder}
              onChange={handleInputChange}
            />
            <Row className={`global-requestButtonRow`}>
              <Col className="d-grid gap-2">
                <Button type="submit" className={`global-requestButton`}>
                  계{"\u00A0\u00A0\u00A0\u00A0\u00A0\u00A0"}산
                </Button>
              </Col>
            </Row>
          </Form>
        </Col>
      </Row>
      <Responses
        show={responsesShow}
        resData={resData}
        getResponsesSummaryItems={getResponsesSummaryItems}
        getResponsesDetailItems={getResponsesDetailItems}
      />
      <DetailGuide
        title="법 무 사 보 수"
        descArr={[
          "It is a long established fact that a reader will be distracted by the readable content of a page when looking at its layout. The point of using Lorem Ipsum is that it has a more-or-less normal distribution of letters, as opposed to using 'Content here, content here'",

          "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged. It was popularised in the 1960s with the release of Letraset sheets containing Lorem Ipsum passages, and more recently with desktop publishing software like Aldus PageMaker including versions of Lorem Ipsum.",

          'The standard chunk of Lorem Ipsum used since the 1500s is reproduced below for those interested. Sections 1.10.32 and 1.10.33 from "de Finibus Bonorum et Malorum" by Cicero are also reproduced in their exact original form, accompanied by English versions from the 1914 translation by H. Rackham.',
        ]}
      />
      <Row className="testData">
        <Col xs="12">
          <br />
          <br />
          <p>Request Data</p>
          <pre>{reqData && JSON.stringify(reqData, null, 4)}</pre>
        </Col>
      </Row>
      <Row className="testData">
        <Col xs="12">
          <br />
          <br />
          <p>Response Data</p>
          {resData && <pre>{JSON.stringify(resData, null, 4)}</pre>}
          {error && errorString}
        </Col>
      </Row>
    </>
  );
}

export default LegalStaffFee;
