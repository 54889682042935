import { useEffect, useState } from "react";
import { Button, Col, Form, Row } from "react-bootstrap";
import { BsBuilding } from "react-icons/bs";
import getURLParamValue from "../../api/getURLParamValue";
import sendCalReq from "../../api/sendCalReq";
import { inputChangeHandler, radioSelectHandler } from "../../api/UiHandlers";
import { hideFormItem, showFormItem, validatePrc } from "../../api/UiUtils";
import FormInputNumberModule from "../FormItems/FormInputNumberModule";
import FormInputRadioModule from "../FormItems/FormInputRadioModule";
import Responses from "../Responses/Responses";
import "../../style/global.css";
import classes from "./HouseBond.module.css";
import DetailGuide from "../DetailGuide/DetailGuide";
import {
  getResponsesSummaryItems,
  getResponsesDetailItems,
} from "../../api/ResponsesViewDataFactory/HouseBondRVDFactory";

function HouseBond() {
  const [optValues, setOptValues] = useState({
    opt1: 1, // 등기원인
    opt2: 1, // 대상지역
    opt3: 1, // 등기대상
    prc1: "", // 시가표준액
    prc2: "", // 건물시가표준액
    prc3: "", // 토지시가표준액
  });
  const [show, setShow] = useState({
    opt3: true,
    prc1: true,
    prc2: false,
    prc3: false,
  });
  const [focus, setFocus] = useState({
    prc1: false,
    prc2: false,
    prc3: false,
  });
  const [reqData, setReqData] = useState(null);
  const [resData, setResData] = useState(null);
  const [loading, setLoading] = useState(null);
  const [error, setError] = useState(null);

  const prcPlaceholder = "1만원이상 금액입력";

  useEffect(() => {
    // show.opt3
    if (optValues.opt1 === 1) {
      showFormItem("opt3", setShow, setOptValues);
    } else {
      hideFormItem("opt3", setShow, setFocus, setOptValues);
    }

    // show.prc1
    if (
      (optValues.opt1 === 1 && optValues.opt3 !== -1 && optValues.opt3 !== 3) ||
      optValues.opt1 === 2
    ) {
      showFormItem("prc1", setShow, setOptValues);
    } else {
      hideFormItem("prc1", setShow, setFocus, setOptValues);
    }

    // show.prc2
    if (optValues.opt3 === 3) {
      showFormItem("prc2", setShow, setOptValues);
    } else {
      hideFormItem("prc2", setShow, setFocus, setOptValues);
    }

    // show.prc3
    if (optValues.opt3 === 3) {
      showFormItem("prc3", setShow, setOptValues);
    } else {
      hideFormItem("prc3", setShow, setFocus, setOptValues);
    }
  }, [optValues.opt1, optValues.opt3]);

  function handleSubmit(e) {
    const opt1 = optValues.opt1;
    const opt2 = optValues.opt2;
    const opt3 = optValues.opt3;
    const prc1 = optValues.prc1;
    const prc2 = optValues.prc2;
    const prc3 = optValues.prc3;

    setReqData({});

    e.preventDefault();
    let reqReady = true;

    // validate prc1
    reqReady &= validatePrc(show.prc1, "prc1", prc1, setFocus, setOptValues);
    // validate prc2
    reqReady &= validatePrc(show.prc2, "prc2", prc2, setFocus, setOptValues);
    // validate prc3
    reqReady &= validatePrc(show.prc3, "prc3", prc2, setFocus, setOptValues);

    if (!reqReady) return;

    console.log("submit");

    let reqObj = {};
    reqObj["등기원인"] = opt1;
    reqObj["대상지역"] = opt2;
    reqObj["등기대상"] = opt3;
    reqObj["시가표준액"] = prc1 === "-1" ? -1 : parseInt(prc1) * 10_000;
    reqObj["건물시가표준액"] = prc2 === "-1" ? -1 : parseInt(prc2) * 10_000;
    reqObj["토지시가표준액"] = prc3 === "-1" ? -1 : parseInt(prc3) * 10_000;

    sendCalReq(
      "/v1/house_bond",
      reqObj,
      setReqData,
      setResData,
      setLoading,
      setError,
      getURLParamValue("s")
    );
  }

  function handleRadioSelect(name, val) {
    radioSelectHandler(name, val, setOptValues, setFocus, true);
  }

  function handleInputChange(name, val) {
    inputChangeHandler(name, val, setOptValues, setFocus, true);
  }

  function freeFocus() {
    setFocus((prevFocus) => ({
      ...prevFocus,
      prc1: false,
      prc2: false,
      prc3: false,
    }));
  }

  // Responses show
  const responsesShow = resData && !error;

  const errorString = error ? <pre>{error.toString()}</pre> : null;

  return (
    <>
      <Row className={`global-calTitle ${classes.calTitleCustom}`}>
        <Col>
          <div>
            <BsBuilding style={{ margin: "0 0 0.5rem 0" }} /> 국 민 주 택 채 권
          </div>
        </Col>
      </Row>
      <Row className={`global-requestForm`}>
        <Col>
          <Form onSubmit={handleSubmit}>
            <FormInputRadioModule
              title="취득방법"
              name="opt1"
              items={[
                { label: "매매.교환", value: 1 },
                { label: "증여.상속", value: 2 },
              ]}
              value={optValues.opt1}
              required
              onSelect={handleRadioSelect}
            />
            <FormInputRadioModule
              title="취득방법"
              name="opt2"
              items={[
                { label: "서울 및 광역시", value: 1 },
                { label: "기타 지역", value: 2 },
              ]}
              value={optValues.opt2}
              required
              onSelect={handleRadioSelect}
            />
            {show.opt3 && (
              <FormInputRadioModule
                title="취득방법"
                name="opt3"
                items={[
                  { label: "주택", value: 1 },
                  { label: "토지", value: 2 },
                  { label: "그외", value: 3 },
                ]}
                value={optValues.opt3}
                required
                onSelect={handleRadioSelect}
              />
            )}
            {show.prc1 && (
              <FormInputNumberModule
                type="price"
                title="시가표준액"
                name="prc1"
                value={optValues.prc1}
                required={false}
                focused={focus.prc1}
                placeholder={prcPlaceholder}
                onChange={handleInputChange}
              />
            )}
            {show.prc2 && (
              <FormInputNumberModule
                type="price"
                title="건물시가표준액"
                name="prc2"
                value={optValues.prc2}
                required={false}
                focused={focus.prc2}
                placeholder={prcPlaceholder}
                onChange={handleInputChange}
              />
            )}
            {show.prc3 && (
              <FormInputNumberModule
                type="price"
                title="토지시가표준액"
                name="prc3"
                value={optValues.prc3}
                required={false}
                focused={focus.prc3}
                placeholder={prcPlaceholder}
                onChange={handleInputChange}
              />
            )}
            <Row className={`global-requestButtonRow`}>
              <Col className="d-grid gap-2">
                <Button type="submit" className={`global-requestButton`}>
                  계{"\u00A0\u00A0\u00A0\u00A0\u00A0\u00A0"}산
                </Button>
              </Col>
            </Row>
          </Form>
        </Col>
      </Row>
      <Responses
        show={responsesShow}
        resData={resData}
        getResponsesSummaryItems={getResponsesSummaryItems}
        getResponsesDetailItems={getResponsesDetailItems}
      />
      <DetailGuide
        title="국 민 주 택 채 권"
        descArr={[
          "It is a long established fact that a reader will be distracted by the readable content of a page when looking at its layout. The point of using Lorem Ipsum is that it has a more-or-less normal distribution of letters, as opposed to using 'Content here, content here'",

          "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged. It was popularised in the 1960s with the release of Letraset sheets containing Lorem Ipsum passages, and more recently with desktop publishing software like Aldus PageMaker including versions of Lorem Ipsum.",

          'The standard chunk of Lorem Ipsum used since the 1500s is reproduced below for those interested. Sections 1.10.32 and 1.10.33 from "de Finibus Bonorum et Malorum" by Cicero are also reproduced in their exact original form, accompanied by English versions from the 1914 translation by H. Rackham.',
        ]}
      />
      <Row className="testData">
        <Col xs="12">
          <br />
          <br />
          <p>Request Data</p>
          <pre>{reqData && JSON.stringify(reqData, null, 4)}</pre>
        </Col>
      </Row>
      <Row className="testData">
        <Col xs="12">
          <br />
          <br />
          <p>Response Data</p>
          {resData && <pre>{JSON.stringify(resData, null, 4)}</pre>}
          {error && errorString}
        </Col>
      </Row>
    </>
  );
}

export default HouseBond;
