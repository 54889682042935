import './App.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import { Container } from 'react-bootstrap';
import MainMenu from './components/MainMenu';
import MainRoute from './components/MainRoute';

function App() {
  return (
    <div>
      <header>
        <MainMenu />
      </header>
      <main className="main">
        <Container fluid className="container">
          <MainRoute />
        </Container>
      </main>
    </div>
  );
}

export default App;
