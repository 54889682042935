// ****************************************
// LoanInterest Responses View Data Factory
// ****************************************
import { getPerString, getPrcString } from "../../api/UiUtils";
/**
 * getResponsesSummaryItems
 *
 * @param {object} resData
 * @returns itemsArr
 */
export function getResponsesSummaryItems(resData) {
  console.log("LoanInterest.", "getResponsesSummaryItems()");
  let itemsArr = [];

  if (resData) {
    if (resData["ret_대출금액"] > 0 && resData["ret_총이자액"] > 0) {
      itemsArr.push({
        name: "총 상환금액",
        value: getPrcString(resData["ret_대출금액"] + resData["ret_총이자액"]),
        additionalInfo: "납입원금 + 대출이자",
      });
    }
    if (resData["ret_총이자액"] > 0) {
      itemsArr.push({
        name: "총 이자액",
        value: getPrcString(resData["ret_총이자액"]),
        additionalInfo: "대출금액 + 총이자액",
      });
    }
  }

  return itemsArr;
}

/**
 * getResponsesDetailItems
 *
 * @param {object} resData
 * @returns itemsArr
 */
export function getResponsesDetailItems(resData) {
  console.log("LoanInterest.", "getResponsesDetailItems()");
  let itemsArr = [];

  if (resData) {
    if (resData["ret_상환방법"] > 0) {
      itemsArr.push({
        type: "data",
        name: "상환방법",
        value:
          resData["ret_상환방법"] === 1
            ? "원리금균등"
            : resData["ret_상환방법"] === 2
            ? "원금균등"
            : resData["ret_상환방법"] === 3
            ? "만기일시"
            : "",
        additionalInfo: "입력값",
      });
    }
    if (resData["ret_대출금액"] > 0) {
      itemsArr.push({
        type: "data",
        name: "대출금액",
        value: getPrcString(resData["ret_대출금액"]),
        additionalInfo: "입력값",
      });
    }
    if (resData["ret_상환기간"] > 0) {
      itemsArr.push({
        type: "data",
        name: "상환기간",
        value: resData["ret_상환기간"] + " 개월",
        additionalInfo: "입력값",
      });
    }
    if (resData["ret_거치기간"] > 0) {
      itemsArr.push({
        type: "data",
        name: "거치기간",
        value: resData["ret_거치기간"] + " 개월",
        additionalInfo: "입력값",
      });
    }
    if (resData["ret_연이자율"] > 0) {
      itemsArr.push({
        type: "data",
        name: "연이자율",
        value: getPerString(resData["ret_연이자율"]),
        additionalInfo: "고정금리",
      });
    }
    if (resData["ret_총이자액"] > 0) {
      itemsArr.push({
        type: "data",
        name: "총이자액",
        value: getPrcString(resData["ret_총이자액"]),
        additionalInfo: "",
      });
    }
    if ((resData["ret_대출금액"] > 0) && (resData["ret_총이자액"] > 0)) {
      itemsArr.push({
        type: "data",
        name: "총상환금액",
        value:
          getPrcString(resData["ret_대출금액"] + resData["ret_총이자액"]),
        additionalInfo: "대출금액 + 총이자액",
      });
    }

    // 상환테이블 추가
    const tableItems = resData["ret_상환테이블"];
    if (Array.isArray(tableItems) && tableItems.length !== 0) {
      itemsArr.push({
        type: "table",
        name: "상환 테이블 (단위:원)",
        headings: ["회차", "상환원금", "이자액", "납부액", "잔액"],
        headingMinWidths: [40, 96, 96, 96, 96],
        items: [],
      });
      tableItems.forEach((item, idx) => {
        itemsArr[itemsArr.length-1].items.push({
          "회차": tableItems[idx]["회차"].toString() + "회",
          "상환원금": getPrcString(tableItems[idx]["상환원금"], true),
          "이자액": getPrcString(tableItems[idx]["이자액"], true),
          "납부액": getPrcString(tableItems[idx]["납부액"], true),
          "잔액": getPrcString(tableItems[idx]["잔액"], true),
        });
      });
    }
  }

  return itemsArr;
}
