import BrokerageFee from "./components/P1/BrokerageFee";
import JeonseInsurance from "./components/P1/JeonseInsurance";
import Contact from "./components/Contact";
import LoanInterest from "./components/P1/LoanInterest";
import P2MConversion from "./components/P1/P2MConversion";
import AreaRatio from "./components/P1/AreaRatio";
import J2MConversion from "./components/P1/J2MConversion";
import DepositInterest from "./components/P1/DepositInterest";

import LegalStaffFee from "./components/P2/LegalStaffFee";
import RegistrationFree from "./components/P2/RegistrationFee";
import AcquisitionTax from "./components/P2/AcquisitionTax";
import HouseBond from "./components/P2/HouseBond";
import StampTax from "./components/P2/StampTax";

const category1 = {
  name: "P1",
  type: "category",
  items: [
    {
      name: "중개보수",
      type: "path",
      path: "/calc/brokerage-free",
      component: <BrokerageFee />,
    },
    {
      name: "전월세전환",
      type: "path",
      path: "/calc/j2m-conversion",
      component: <J2MConversion />,
    },
    {
      name: "전세보증보험",
      type: "path",
      path: "/calc/jeonse-insurance",
      component: <JeonseInsurance />,
    },
    {
      name: "평수환산",
      type: "path",
      path: "/calc/p2m-conversion",
      component: <P2MConversion />,
    },
    {
      name: "건폐율·용적율",
      type: "path",
      path: "/calc/area-ratio",
      component: <AreaRatio />,
    },
    {
      name: "대출이자",
      type: "path",
      path: "/calc/loan-interest",
      component: <LoanInterest />,
    },
    {
      name: "예적금이자",
      type: "path",
      path: "/calc/deposit-interest",
      component: <DepositInterest />,
    },
    // {
    //   type: "divider",
    // },
    // {
    //   name: "광고",
    //   type: "link",
    //   link: "https://naver.com",
    // },
  ],
};

const category2 = {
  name: "P2",
  type: "category",
  items: [
    {
      name: "법무사보수",
      type: "path",
      path: "/calc/legal-staff-fee",
      component: <LegalStaffFee />,
    },
    {
      name: "등기비용",
      type: "path",
      path: "/cal/registration-fee",
      component: <RegistrationFree />,
    },
    {
      name: "취득세",
      type: "path",
      path: "/cal/acquisition-tax",
      component: <AcquisitionTax />,
    },
    {
      name: "국민주택채권",
      type: "path",
      path: "/cal/house-bond",
      component: <HouseBond />,
    },
    {
      name: "인지세",
      type: "path",
      path: "/cal/stamp-tax",
      component: <StampTax />,
    },
  ],
};

const path1 = {
  name: '컨택트',
  type: 'path',
  path: '/contact',
  component: <Contact/>,
};

const link1 = {
  name: 'JIRA',
  type: 'link',
  link: 'https://wittlab.atlassian.net',
  component: null,
};

const menus = {
  categories: [
    category1,
    category2,
    path1,
    link1,
  ]
};

export default menus;
