import React from "react";
import { Row, Col } from "react-bootstrap";
import classes from "./FormHrModule.module.css";

function FormHrModule(props) {

  return (
    <>
      <Row>
        <Col>
          <hr className={classes.hr} />
        </Col>
      </Row>
    </>
  );
}

export default FormHrModule;
