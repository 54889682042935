// ******************************************
// AcquisitionTax Responses View Data Factory
// ******************************************
import { getPerString, getPrcString } from "../../api/UiUtils";
/**
 * getResponsesSummaryItems
 *
 * @param {object} resData
 * @returns itemsArr
 */
export function getResponsesSummaryItems(resData) {
  console.log("RegistrationFee.", "getResponsesSummaryItems()");
  let itemsArr = [];

  if (resData) {
    if (resData["ret_감면후취득세"] > 0) {
      itemsArr.push({
        name: "취득세 감면 후 금액",
        value: getPrcString(resData["ret_감면후취득세"]),
        additionalInfo: "부가정보",
      });
    }
  }

  return itemsArr;
}

/**
 * getResponsesDetailItems
 *
 * @param {object} resData
 * @returns itemsArr
 */
export function getResponsesDetailItems(resData) {
  console.log("RegistrationFee.", "getResponsesDetailItems()");
  let itemsArr = [];

  if (resData) {
    if (resData["ret_취득가액"] > 0) {
      itemsArr.push({
        type: "data",
        name: "금액",
        value: getPrcString(resData["ret_취득가액"]),
        additionalInfo: "부가정보",
      });
    }
    if (resData["ret_시가표준액"] > 0) {
      itemsArr.push({
        type: "data",
        name: "시가표준액",
        value: getPrcString(resData["ret_시가표준액"]),
        additionalInfo: "부가정보",
      });
    }
    if (resData["ret_취득세율"] > 0) {
      itemsArr.push({
        type: "data",
        name: "취득세율",
        value: getPerString(resData["ret_취득세율"]),
        additionalInfo: "부가정보",
      });
    }
    if (resData["ret_지방교육세포함_취득세율"] > 0) {
      itemsArr.push({
        type: "data",
        name: "지방교육세 포함",
        value: getPerString(resData["ret_지방교육세포함_취득세율"]),
        additionalInfo: "부가정보",
      });
    }
    if (resData["ret_농어촌특별세포함_취득세율"] > 0) {
      itemsArr.push({
        type: "data",
        name: "농어촌특별세 포함",
        value: getPerString(resData["ret_농어촌특별세포함_취득세율"]),
        additionalInfo: "부가정보",
      });
    }
    if (resData["ret_취득세"] > 0) {
      itemsArr.push({
        type: "data",
        name: "취득세",
        value: getPrcString(resData["ret_취득세"]),
        additionalInfo: "부가정보",
      });
    }
    if (resData["ret_감면후취득세"] > 0) {
      itemsArr.push({
        type: "data",
        name: "취득세 감면 후",
        value: getPrcString(resData["ret_감면후취득세"]),
        additionalInfo: "부가정보",
      });
    }
  }

  return itemsArr;
}
