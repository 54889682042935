// *****************************************
// LegalStaffFee Responses View Data Factory
// *****************************************
import { getPerString, getPrcString } from "../../api/UiUtils";
/**
 * getResponsesSummaryItems
 *
 * @param {object} resData
 * @returns itemsArr
 */
export function getResponsesSummaryItems(resData) {
  console.log("LegalStaffFee.", "getResponsesSummaryItems()");
  let itemsArr = [];

  if (resData) {
    if (resData["ret_총계"] > 0) {
      itemsArr.push({
        name: "총 법무비용",
        value: getPrcString(resData["ret_총계"]),
        additionalInfo: "부가정보",
      });
    }
  }

  return itemsArr;
}

/**
 * getResponsesDetailItems
 *
 * @param {object} resData
 * @returns itemsArr
 */
export function getResponsesDetailItems(resData) {
  console.log("LegalStaffFee.", "getResponsesDetailItems()");
  let itemsArr = [];

  if (resData) {
    if (resData["ret_매매가"] > 0) {
      itemsArr.push({
        type: "data",
        name: "매매가",
        value: getPrcString(resData["ret_매매가"]),
        additionalInfo: "부가정",
      });
    }
    if (resData["ret_법무사등기보수"] > 0) {
      itemsArr.push({
        type: "data",
        name: "법무사 등기 보수",
        value: getPrcString(resData["ret_법무사등기보수"]),
        additionalInfo: "부가정보",
      });
    }
    if (resData["ret_보수부가세"] > 0) {
      itemsArr.push({
        type: "data",
        name: "보수 부가세",
        value: getPrcString(resData["ret_보수부가세"]),
        additionalInfo: "부가정보",
      });
    }
    if (resData["ret_제증명비용"] > 0) {
      itemsArr.push({
        type: "data",
        name: "제증명비용",
        value: getPrcString(resData["ret_제증명비용"]),
        additionalInfo: "부가정보",
      });
    }
    if (resData["ret_일당및교통비"] > 0) {
      itemsArr.push({
        type: "data",
        name: "일당 및 교통비",
        value: getPrcString(resData["ret_일당및교통비"]),
        additionalInfo: "부가정보",
      });
    }
    if (resData["ret_보수액소계"] > 0) {
      itemsArr.push({
        type: "data",
        name: "보수액 소계",
        value: getPrcString(resData["ret_보수액소계"]),
        additionalInfo: "부가정보",
      });
    }
    itemsArr.push({
      type: "hr",
    });
    if (resData["ret_수입증지비용"] > 0) {
      itemsArr.push({
        type: "data",
        name: "수입증지",
        value: getPrcString(resData["ret_수입증지비용"]),
        additionalInfo: "부가정보",
      });
    }
    if (resData["ret_수입인지비용"] > 0) {
      itemsArr.push({
        type: "data",
        name: "수입인지",
        value: getPrcString(resData["ret_수입인지비용"]),
        additionalInfo: "부가정보",
      });
    }
    if (resData["ret_공과금소계"] > 0) {
      itemsArr.push({
        type: "data",
        name: "공과금 소계",
        value: getPrcString(resData["ret_공과금소계"]),
        additionalInfo: "부가정보",
      });
    }
    itemsArr.push({
      type: "hr",
    });
    if (resData["ret_총계"] > 0) {
      itemsArr.push({
        type: "data",
        name: "총계",
        value: getPrcString(resData["ret_총계"]),
        additionalInfo: "부가정보",
      });
    }
  }

  return itemsArr;
}
