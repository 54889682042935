import { useEffect, useState } from "react";
import { BsBuilding } from "react-icons/bs";
import { Form, Row, Col, Button } from "react-bootstrap";
import FormInputRadioModule from "../FormItems/FormInputRadioModule";
import FormInputCheckModule from "../FormItems/FormInputCheckModule";
import FormInputNumberModule from "../FormItems/FormInputNumberModule";
import sendCalReq from "../../api/sendCalReq";
import Responses from "../Responses/Responses";
import getURLParamValue from "../../api/getURLParamValue";
import {
  showFormItem,
  hideFormItem,
  validateMon,
  validatePer,
  validatePrc,
} from "../../api/UiUtils";
import {
  checkChangeHandler,
  inputChangeHandler,
  radioSelectHandler,
} from "../../api/UiHandlers";
import "../../style/global.css";
import classes from "./LoanInterest.module.css";
import DetailGuide from "../DetailGuide/DetailGuide";
import {
  getResponsesDetailItems,
  getResponsesSummaryItems,
} from "../../api/ResponsesViewDataFactory/LoanInterestRVDFactory";

function LoanInterest() {
  const [optValues, setOptValues] = useState({
    opt1: 1, // 상환방법
    chk1: false, // 거치기간체크
    prc1: "", // 대출금액
    mon1: "", // 대출기간
    mon2: "", // 거치기간
    per1: "", // 연이자율
  });
  const [show, setShow] = useState({
    chk1: true,
    mon2: false,
  });
  const [focus, setFocus] = useState({
    prc1: false,
    mon1: false,
    mon2: false,
    per1: false,
  });
  const [reqData, setReqData] = useState(null);
  const [resData, setResData] = useState(null);
  const [loading, setLoading] = useState(null);
  const [error, setError] = useState(null);

  const prcPlaceholder = "금액 입력";
  const monPlaceholder = "총개월수 입력";
  const perPlaceholder = "0.01~100 사이값 입력";

  useEffect(() => {
    // show.chk1
    if (optValues.opt1 === 1 || optValues.opt1 === 2) {
      showFormItem("chk1", setShow, setOptValues);
    } else {
      hideFormItem("chk1", setShow, setFocus, setOptValues);
    }
    // show.mon2
    if (optValues.chk1) {
      showFormItem("mon2", setShow, setOptValues);
    } else {
      hideFormItem("mon2", setShow, setFocus, setOptValues);
    }
  }, [optValues.opt1, optValues.opt2, optValues.chk1]);

  function handleSubmit(e) {
    const opt1 = optValues.opt1;
    const chk1 = optValues.chk1;
    const prc1 = optValues.prc1;
    const mon1 = optValues.mon1;
    const mon2 = optValues.mon2;
    const per1 = optValues.per1;

    setReqData({});

    e.preventDefault();
    let reqReady = true;

    // validate prc1 : 대출금액
    reqReady &= validatePrc(true, "prc1", prc1, setFocus, setOptValues);
    // validate mon1 : 대출기간
    reqReady &= validateMon(true, "mon1", mon1, setFocus, setOptValues);
    // validate mon2 : 거치기간
    reqReady &= validateMon(show.mon2, "mon2", mon2, setFocus, setOptValues);
    // validate per1 : 연이자율
    reqReady &= validatePer(true, "per1", per1, setFocus, setOptValues);

    if (!reqReady) return;

    let reqObj = {};
    reqObj["상환방법"] = opt1;
    reqObj["거치기간체크"] = chk1;
    reqObj["대출금액"] = prc1 === "-1" ? -1 : parseInt(prc1) * 10000;
    reqObj["대출기간"] = mon1 === "-1" ? -1 : parseInt(mon1);
    reqObj["거치기간"] = mon2 === "-1" ? -1 : parseInt(mon2);
    reqObj["연이자율"] = per1 === "-1" ? -1 : parseFloat(per1);

    sendCalReq(
      "/v1/loan_interest",
      reqObj,
      setReqData,
      setResData,
      setLoading,
      setError,
      getURLParamValue("s")
    );
  }

  function handleRadioSelect(name, val) {
    radioSelectHandler(name, val, setOptValues, setFocus, true);
  }

  function handleCheckChange(name, val) {
    checkChangeHandler(name, val, setOptValues, true);
  }

  function handleInputChange(name, val) {
    inputChangeHandler(name, val, setOptValues, setFocus, true);
  }

  function freeFocus() {
    setFocus((prevFocus) => ({
      ...prevFocus,
      prc1: false,
      mon1: false,
      mon2: false,
      per1: false,
    }));
  }

  // Respones show
  const responsesShow = resData && !error;

  const errorString = error ? <pre>{error.toString()}</pre> : null;
  // const result_success = resData ? (
  //   <ul>
  //     <li>상환방법 : {resData["ret_상환방법"]}</li>
  //     <li>대출금액 : ₩{resData["ret_대출금액"]}</li>
  //     <li>상환기간 : {resData["ret_상환기간"]}개월</li>
  //     <li>거치기간 : {resData["ret_거치기간"]}개월</li>
  //     <li>연이자율 : {resData["ret_연이자율"]}%</li>
  //     <li>총이자액 : ₩{resData["ret_총이자액"]}</li>
  //     <li>총상환금액 : ₩{resData["ret_총상환금액"]}</li>
  //     <li>상환테이블</li>
  //     <ul>
  //       {resData["ret_상환테이블"].map((item, idx) => {
  //         return (
  //           <li key={idx}>
  //             회차 : {item["회차"]}
  //             <ul>
  //               <li>상환원금 : {item["상환원금"]}</li>
  //               <li>이자액 : {item["이자액"]}</li>
  //               <li>납부액 : {item["납부액"]}</li>
  //               <li>잔액 : {item["잔액"]}</li>
  //             </ul>
  //           </li>
  //         );
  //       })}
  //     </ul>
  //   </ul>
  // ) : null;

  return (
    <>
      <Row className={`global-calTitle ${classes.calTitleCustom}`}>
        <Col>
          <div>
            <BsBuilding style={{ margin: "0 0 0.5rem 0" }} /> 대 출 이 자
          </div>
        </Col>
      </Row>
      <Row className={`global-requestForm`}>
        <Col>
          <Form onSubmit={handleSubmit}>
            <FormInputRadioModule
              title="상환방법"
              name="opt1"
              items={[
                { label: "원리금균등", value: 1 },
                { label: "원금균등", value: 2 },
                { label: "만기일시", value: 3 },
              ]}
              value={optValues.opt1}
              required
              onSelect={handleRadioSelect}
              xsL={12}
              xsR={12}
            />
            {show.chk1 && (
              <FormInputCheckModule
                title="거치기간"
                name="chk1"
                label=""
                onChange={handleCheckChange}
              />
            )}
            <FormInputNumberModule
              type="price"
              title="대출금액"
              name="prc1"
              value={optValues.prc1}
              required={false}
              focused={focus.prc1}
              placeholder={prcPlaceholder}
              onChange={handleInputChange}
            />
            <FormInputNumberModule
              type="month"
              title="대출기간"
              name="mon1"
              value={optValues.mon1}
              required={false}
              focused={focus.mon1}
              placeholder={monPlaceholder}
              onChange={handleInputChange}
            />
            {show.mon2 && (
              <FormInputNumberModule
                type="month"
                title="거치기간"
                name="mon2"
                value={optValues.mon2}
                required={false}
                focused={focus.mon2}
                placeholder={monPlaceholder}
                onChange={handleInputChange}
              />
            )}
            <FormInputNumberModule
              type="percentage"
              title="연이자율"
              name="per1"
              value={optValues.per1}
              required={false}
              focused={focus.per1}
              placeholder={perPlaceholder}
              onChange={handleInputChange}
            />
            <Row className={`global-requestButtonRow`}>
              <Col className="d-grid gap-2">
                <Button type="submit" className={`global-requestButton`}>
                  계{"\u00A0\u00A0\u00A0\u00A0\u00A0\u00A0"}산
                </Button>
              </Col>
            </Row>
          </Form>
        </Col>
      </Row>
      <Responses
        show={responsesShow}
        resData={resData}
        getResponsesSummaryItems={getResponsesSummaryItems}
        getResponsesDetailItems={getResponsesDetailItems}
      />
      <DetailGuide
        title="대 출 이 자"
        descArr={[
          "! 대출 이자 계산 결과에 대해 보장하지 않으며, 참고용으로 사용하시기 바랍니다.",

          "대출받은 금액에 대한 이자를 계산합니다. 선택하신 옵션에 따라 이자 금액이 달라지므로 대출 정보에 대해 정확히 확인 하신 후 계산 하시기 바랍니다. 월별 자세한 사항은 상환 테이블 통해 확인하실 수 있습니다.",

          "[ 상환 방법 ]",

          "원리금 균등 : 매월 동일한 원금과 이자를 상환하는 방식",

          "원금 균등 : 매월 동일한 원금과 남은 잔금에 상응하는 이자를 매월 상환하는 방식",

          "만기일시 : 대출 기간 동안 매월 이자만 상환하고, 원금은 만기에 전액 상환하는 방식",
        ]}
      />
      <Row className="testData">
        <Col xs="12">
          <br />
          <br />
          <p>Request Data</p>
          <pre>{reqData && JSON.stringify(reqData, null, 4)}</pre>
        </Col>
      </Row>
      <Row className="testData">
        <Col xs="12">
          <br />
          <br />
          <p>Response Data</p>
          {resData && <pre>{JSON.stringify(resData, null, 4)}</pre>}
          {error && errorString}
        </Col>
      </Row>
    </>
  );
}

export default LoanInterest;
