import { useEffect, useState } from "react";
import { Button, Col, Form, Row } from "react-bootstrap";
import { BsBuilding } from "react-icons/bs";
import getURLParamValue from "../../api/getURLParamValue";
import sendCalReq from "../../api/sendCalReq";
import { checkChangeHandler, inputChangeHandler, radioSelectHandler } from "../../api/UiHandlers";
import { hideFormItem, showFormItem, validatePrc } from "../../api/UiUtils";
import FormInputCheckModule from "../FormItems/FormInputCheckModule";
import FormInputNumberModule from "../FormItems/FormInputNumberModule";
import FormInputRadioModule from "../FormItems/FormInputRadioModule";
import Responses from "../Responses/Responses";
import "../../style/global.css";
import classes from "./AcquisitionTax.module.css";
import DetailGuide from "../DetailGuide/DetailGuide";
import {
  getResponsesSummaryItems,
  getResponsesDetailItems,
} from "../../api/ResponsesViewDataFactory/AcquisitionTaxRVDFactory";

function AcquisitionTax() {
  const [optValues, setOptValues] = useState({
    opt1: 1, // 취득방법
    opt2: 1, // 취득대상
    opt3: 1, // 대상면적
    opt4: 1, // 대상주택
    chk1: false, // 법인
    chk2: false, // 임대사업자_최초분양
    chk3: false, // 조정대상지역
    chk4: false, // 중과배제
    chk5: false, // 생애최초구입
    chk6: false, // 수도권
    chk7: false, // 일주택자_배우자및직계비속_증여
    prc1: "", // 취득가액
    prc2: "", // 취득시가표준액
  });
  const [show, setShow] = useState({
    opt3: true,
    opt4: true,
    chk1: false,
    chk2: false,
    chk3: false,
    chk4: false,
    chk6: false,
    chk7: false,
    prc1: false,
  });
  const [focus, setFocus] = useState({
    opt4: false,
    prc1: false,
    prc2: false,
  });
  const [reqData, setReqData] = useState(null);
  const [resData, setResData] = useState(null);
  const [loading, setLoading] = useState(null);
  const [error, setError] = useState(null);

  const prcPlaceholder = "1만원이상 금액입력";

  useEffect(() => {
    const opt1 = optValues.opt1;
    const opt2 = optValues.opt2;
    const opt3 = optValues.opt3;
    const opt4 = optValues.opt4;
    const chk2 = optValues.chk2;
    const chk5 = optValues.chk5;

    // show.opt3
    if (opt2 !== -1 && opt2 !== 3) {
      showFormItem("opt3", setShow, setOptValues);
    } else {
      hideFormItem("opt3", setShow, setFocus, setOptValues);
    }

    // show.opt4
    if (opt1 === 1) {
      showFormItem("opt4", setShow, setOptValues);
    } else {
      hideFormItem("opt4", setShow, setFocus, setOptValues);
    }

    // show.chk1
    if (opt2 === 1) {
      showFormItem("chk1", setShow, setOptValues);
    } else {
      hideFormItem("chk1", setShow, setFocus, setOptValues);
    }

    // show.chk2
    if (opt2 !== -1 && opt2 !== 3) {
      showFormItem("chk2", setShow, setOptValues);
    } else {
      hideFormItem("chk2", setShow, setFocus, setOptValues);
    }

    // show.chk3
    if (opt2 === 1) {
      showFormItem("chk3", setShow, setOptValues);
    } else {
      hideFormItem("chk3", setShow, setFocus, setOptValues);
    }

    // show.chk4
    if (
      opt1 !== -1 &&
      opt2 !== -1 &&
      opt4 !== -1 &&
      opt1 === 1 &&
      !(opt2 === 1 && opt4 === 1)
    ) {
      showFormItem("chk4", setShow, setOptValues);
    } else {
      hideFormItem("chk4", setShow, setFocus, setOptValues);
    }

    // show.chk6
    if (
      opt1 !== -1 &&
      opt2 !== -1 &&
      opt3 !== -1 &&
      opt1 === 1 &&
      opt2 !== 3 &&
      ((chk2 && (opt3 === 1 || opt3 === 2)) || chk5)
    ) {
      showFormItem("chk6", setShow, setOptValues);
    } else {
      hideFormItem("chk6", setShow, setFocus, setOptValues);
    }

    // show.chk7
    if (opt1 === 2 && opt2 === 1) {
      showFormItem("chk7", setShow, setOptValues);
    } else {
      hideFormItem("chk7", setShow, setFocus, setOptValues);
    }

    // show.prc1
    if (opt1 === 1) {
      showFormItem("prc1", setShow, setOptValues);
    } else {
      hideFormItem("prc1", setShow, setFocus, setOptValues);
    }
  }, [
    optValues.opt1,
    optValues.opt2,
    optValues.opt3,
    optValues.opt4,
    optValues.chk2,
    optValues.chk5,
  ]);

  function handleSubmit(e) {
    const opt1 = optValues.opt1;
    const opt2 = optValues.opt2;
    const opt3 = optValues.opt3;
    const opt4 = optValues.opt4;
    const chk1 = optValues.chk1;
    const chk2 = optValues.chk2;
    const chk3 = optValues.chk3;
    const chk4 = optValues.chk4;
    const chk5 = optValues.chk5;
    const chk6 = optValues.chk6;
    const chk7 = optValues.chk7;
    const prc1 = optValues.prc1;
    const prc2 = optValues.prc2;

    setReqData({});

    e.preventDefault();
    let reqReady = true;

    if (opt1 === 1 && opt2 === 1 && (opt4 === 3 || opt4 === 4)) {
      setFocus((prevFocus) => ({ ...prevFocus, opt4: true }));
      reqReady &= false;
    } else {
      setFocus((prevFocus) => ({ ...prevFocus, opt4: false }));
    }

    // validate prc1
    reqReady &= validatePrc(show.prc1, "prc1", prc1, setFocus, setOptValues);
    // validate prc2
    reqReady &= validatePrc(true, "prc2", prc2, setFocus, setOptValues);

    if (!reqReady) return;

    console.log("submit");

    let reqObj = {};
    reqObj["취득방법"] = opt1;
    reqObj["취득대상"] = opt2;
    reqObj["대상면적"] = opt3;
    reqObj["대상주택"] = opt4;
    reqObj["법인"] = chk1;
    reqObj["임대사업자_최초분양"] = chk2;
    reqObj["조정대상지역"] = chk3;
    reqObj["중과배제"] = chk4;
    reqObj["생애최초구입"] = chk5;
    reqObj["수도권"] = chk6;
    reqObj["일주택자_배우자및직계비속_증여"] = chk7;
    reqObj["취득가액"] = prc1 === "-1" ? -1 : parseInt(prc1) * 10_000;
    reqObj["시가표준액"] = prc2 === "-1" ? -1 : parseInt(prc2) * 10_000;

    sendCalReq(
      "/v1/acquisition_tax",
      reqObj,
      setReqData,
      setResData,
      setLoading,
      setError,
      getURLParamValue("s")
    );
  }

  function handleRadioSelect(name, val) {
    radioSelectHandler(name, val, setOptValues, setFocus, true);
  }

  function handleCheckChange(name, val) {
    checkChangeHandler(name, val, setOptValues, true);
  }

  function handleInputChange(name, val) {
    inputChangeHandler(name, val, setOptValues, setFocus, true);
  }

  function freeFocus() {
    setFocus((prevFocus) => ({
      ...prevFocus,
      prc1: false,
      prc2: false,
    }));
  }

  // Responses show
  const responsesShow = resData && !error;

  const errorString = error ? <pre>{error.toString()}</pre> : null;

  return (
    <>
      <Row className={`global-calTitle ${classes.calTitleCustom}`}>
        <Col>
          <div>
            <BsBuilding style={{ margin: "0 0 0.5rem 0" }} /> 취득세
          </div>
        </Col>
      </Row>
      <Row className={`global-requestForm`}>
        <Col>
          <Form onSubmit={handleSubmit}>
            <FormInputRadioModule
              title="취득방법"
              name="opt1"
              items={[
                { label: "매매", value: 1 },
                { label: "증여", value: 2 },
                { label: "상속", value: 3 },
                { label: "원시", value: 4 },
              ]}
              value={optValues.opt1}
              required
              onSelect={handleRadioSelect}
            />
            <FormInputRadioModule
              title="취득대상"
              name="opt2"
              items={[
                { label: "주택", value: 1 },
                { label: "오피스텔", value: 2 },
                { label: "그외건물", value: 3 },
              ]}
              value={optValues.opt2}
              required
              onSelect={handleRadioSelect}
            />
            {show.opt3 && (
              <FormInputRadioModule
                title="대상면적"
                name="opt3"
                items={[
                  { label: "40m²이하", value: 1 },
                  { label: "60m²이하", value: 2 },
                  { label: "85m²이하", value: 3 },
                  { label: "85m²초과", value: 4 },
                ]}
                value={optValues.opt3}
                required
                onSelect={handleRadioSelect}
                xsL={12}
                xsR={12}
              />
            )}
            {show.opt4 && (
              <FormInputRadioModule
                title="대상주택"
                name="opt4"
                items={
                  optValues.opt2 === 1
                    ? [
                        { label: "1주택", value: 1 },
                        { label: "2주택", value: 2 },
                      ]
                    : [
                        { label: "1주택", value: 1 },
                        { label: "2주택", value: 2 },
                        { label: "3주택", value: 3 },
                        { label: "그이상", value: 4 },
                      ]
                }
                value={optValues.opt4}
                required
                focused={focus.opt4}
                onSelect={handleRadioSelect}
              />
            )}
            {show.chk1 && (
              <FormInputCheckModule
                title="법인"
                name="chk1"
                label=""
                onChange={handleCheckChange}
                xsL={6}
                xsR={6}
              />
            )}
            {show.chk2 && (
              <FormInputCheckModule
                title="임대사업자 최초분양"
                name="chk2"
                label=""
                onChange={handleCheckChange}
                xsL={6}
                xsR={6}
              />
            )}
            {show.chk3 && (
              <FormInputCheckModule
                title="조정대상지역"
                name="chk3"
                label=""
                onChange={handleCheckChange}
                xsL={6}
                xsR={6}
              />
            )}
            {show.chk4 && (
              <FormInputCheckModule
                title="중과배제"
                name="chk4"
                label=""
                onChange={handleCheckChange}
                xsL={6}
                xsR={6}
              />
            )}
            <FormInputCheckModule
              title="생애최초구입"
              name="chk5"
              label=""
              onChange={handleCheckChange}
              xsL={6}
              xsR={6}
            />
            {show.chk6 && (
              <FormInputCheckModule
                title="수도권"
                name="chk6"
                label=""
                onChange={handleCheckChange}
                xsL={6}
                xsR={6}
              />
            )}
            {show.chk7 && (
              <FormInputCheckModule
                title="1주택자 배우자 및 직계비속 증여"
                name="chk7"
                label=""
                onChange={handleCheckChange}
                xsL={9}
                xsR={3}
                mdL={9}
                mdR={3}
              />
            )}
            {show.prc1 && (
              <FormInputNumberModule
                type="price"
                title="취득가액"
                name="prc1"
                value={optValues.prc1}
                required={false}
                focused={focus.prc1}
                placeholder={prcPlaceholder}
                onChange={handleInputChange}
              />
            )}
            <FormInputNumberModule
              type="price"
              title="시가표준액"
              name="prc2"
              value={optValues.prc2}
              required={false}
              focused={focus.prc2}
              placeholder={prcPlaceholder}
              onChange={handleInputChange}
            />
            <Row className={`global-requestButtonRow`}>
              <Col className="d-grid gap-2">
                <Button type="submit" className={`global-requestButton`}>
                  계{"\u00A0\u00A0\u00A0\u00A0\u00A0\u00A0"}산
                </Button>
              </Col>
            </Row>
          </Form>
        </Col>
      </Row>
      <Responses
        show={responsesShow}
        resData={resData}
        getResponsesSummaryItems={getResponsesSummaryItems}
        getResponsesDetailItems={getResponsesDetailItems}
      />
      <DetailGuide
        title="취 득 세"
        descArr={[
          "It is a long established fact that a reader will be distracted by the readable content of a page when looking at its layout. The point of using Lorem Ipsum is that it has a more-or-less normal distribution of letters, as opposed to using 'Content here, content here'",

          "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged. It was popularised in the 1960s with the release of Letraset sheets containing Lorem Ipsum passages, and more recently with desktop publishing software like Aldus PageMaker including versions of Lorem Ipsum.",

          'The standard chunk of Lorem Ipsum used since the 1500s is reproduced below for those interested. Sections 1.10.32 and 1.10.33 from "de Finibus Bonorum et Malorum" by Cicero are also reproduced in their exact original form, accompanied by English versions from the 1914 translation by H. Rackham.',
        ]}
      />
      <Row className="testData">
        <Col xs="12">
          <br />
          <br />
          <p>Request Data</p>
          <pre>{reqData && JSON.stringify(reqData, null, 4)}</pre>
        </Col>
      </Row>
      <Row className="testData">
        <Col xs="12">
          <br />
          <br />
          <p>Response Data</p>
          {resData && <pre>{JSON.stringify(resData, null, 4)}</pre>}
          {error && errorString}
        </Col>
      </Row>
    </>
  );
}

export default AcquisitionTax;
